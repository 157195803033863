import { parseWithZod } from '@conform-to/zod';
import { datadogRum } from '@datadog/browser-rum';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type DataFunctionArgs,
  json,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData,
  useLocation } from
'@remix-run/react';

import { withSentry } from '@sentry/remix';
import { storyblokInit, apiPlugin, RichTextSchema } from '@storyblok/react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';

import BrandCard from '#app/components/storyblok/brand-card';
import CardList from '#app/components/storyblok/card-list';
import CategoryCard from '#app/components/storyblok/category-card';

import PerkWithIcon from '#app/components/storyblok/perk-with-icon';

import UnorderedList from '#app/components/storyblok/unordered-list';
import { csrf } from '#app/cookies/csrf.server';
import PageIsDown from '#app/features/checkout/components/page-is-down';
import { getUserId, logout } from '#app/server/auth.server.ts';
import { ensureValidCartId } from '#app/server/commerce-tools/cart/ensureValidCartId.server.ts';
import { getEnv } from '#app/server/utils/env.server';
import { getHints, useHints } from '#app/utils/client-hints';
import { isPreview } from '#app/utils/isPreview.tsx';
import { prisma } from '#app/utils/db.server.ts';
import { useNonce } from '#app/utils/nonce-provider';
import { useRequestInfo } from '#app/utils/request-info';
import { getTheme, type Theme } from '#app/utils/theme/theme.server';
import { type PlentyCart } from '#types/cart';
import ContactModal from './components/contact-modal';
import { GeneralErrorBoundary } from './components/error-boundary';
import AccordionBlok from './components/storyblok/accordion-blok';
import AlignContainer from './components/storyblok/align-container';
import BodyBackgroundTextBlok from './components/storyblok/body-background-text';
import Body from './components/storyblok/body-blok';
import BrandsBrandCard from './components/storyblok/brands-brand-card';
import Button from './components/storyblok/button-blok';
import ContentBlok from './components/storyblok/content-blok';
import FaqGrid from './components/storyblok/faq-grid';
import FullscreenImage from './components/storyblok/fullscreen-image';
import FullScreenImageCenteredTextBlok from './components/storyblok/fullscreen-image-centered-text';
import GridColumns from './components/storyblok/grid-columns';
import Heading from './components/storyblok/heading-blok';
import HorizontalStack from './components/storyblok/horizontal-stack';
import ImageBlok from './components/storyblok/image-blok';
import LinkedText from './components/storyblok/linked-text';
import MarketingImageCard from './components/storyblok/marketing-image-card';
import Page from './components/storyblok/page-blok';
import ProductCarousel from './components/storyblok/product-carousel';
import ProductSlider from './components/storyblok/product-slider';
import SearchBrandHStack from './components/storyblok/search-brand-hstack';
import SearchTrendingContent from './components/storyblok/search-clerk-trending';
import SearchInformationCard from './components/storyblok/search-infomation-card';
import SearchMarketingCard from './components/storyblok/search-marketing-card';
import SearchNoResults from './components/storyblok/search-no-result';
import Section from './components/storyblok/section-blok';
import lgSpacer from './components/storyblok/spacings/lgSpacer';
import mdSpacer from './components/storyblok/spacings/mdSpacer';
import smSpacer from './components/storyblok/spacings/smSpacer';
import xlSpacer from './components/storyblok/spacings/xlSpacer';
import xsSpacer from './components/storyblok/spacings/xsSpacer';
import StoreCard from './components/storyblok/store-card';
import StoreHeader from './components/storyblok/store-header';
import StoreHeroImage from './components/storyblok/store-hero-image';
import StoresStoreCard from './components/storyblok/stores-store-card';
import Video from './components/storyblok/video-blok';
import { EpicToaster } from './components/toaster';
import { getToast } from './cookies/toast.server';
import { getVisitorAndHeader } from './cookies/visitor.server.ts';
import UseCookieScript from './hooks/useCookieScript';
import UseGoogleAdsScript from './hooks/useGoogleAdsScript';
import useGoogleTagManagerScript from './hooks/useGoogleTagManagerScript';
import UseMetaPixel from './hooks/useMetaPixel';
import { getPlentyCart } from './server/commerce-tools/cart/getPlentyCart.server';
import { honeypot } from './server/utils/honeypot.server';
import { makeTimings, time } from './server/utils/timing.server';
import { useCartStore } from './store/cart';
import { useClerkVisitorStore } from './store/clerk-visitor';
import fontStyleSheetUrl from './styles/font.css';
import sonnerStyleSheetUrl from './styles/sonner.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { languageSession } from './utils/localisation/lang.server';
import { getLanguageFromCookieOrBrowserOrFallback } from './utils/localisation/lokalisation-utilities';
import { combineHeaders, getDomainUrl } from './utils/misc';

// import { RichTextSchema } from "@storyblok/js";

const components = {
  gridColumns: GridColumns,
  productCarousel: ProductCarousel,
  page: Page,
  heading: Heading,
  section: Section,
  marketingImageCard: MarketingImageCard,
  categoryCard: CategoryCard,
  cardList: CardList,
  brandCard: BrandCard,
  storeCard: StoreCard,
  perkWithIcon: PerkWithIcon,
  unorderedList: UnorderedList,
  horizontalStack: HorizontalStack,
  body: Body,
  button: Button,
  accordion: AccordionBlok,
  alignContainer: AlignContainer,
  productSlider: ProductSlider,
  video: Video,
  faqGrid: FaqGrid,
  fullscreenImage: FullscreenImage,
  xsSpacer: xsSpacer,
  smSpacer: smSpacer,
  mdSpacer: mdSpacer,
  lgSpacer: lgSpacer,
  xlSpacer: xlSpacer,
  storeHeader: StoreHeader,
  ContentBlok: ContentBlok,
  storeHeroImage: StoreHeroImage,
  imageBlok: ImageBlok,
  storesStoreCard: StoresStoreCard,
  brandsBrandCard: BrandsBrandCard,
  fullScreenImageCenteredText: FullScreenImageCenteredTextBlok,
  contactButton: ContactModal,
  linkedText: LinkedText,
  searchTrendingContent: SearchTrendingContent,
  bodyBackgroundText: BodyBackgroundTextBlok,
  searchMarketingCard: SearchMarketingCard,
  searchInformationCard: SearchInformationCard,
  searchBrandHStack: SearchBrandHStack,
  searchNoResults: SearchNoResults
};
storyblokInit({
  //Well we have to hide this
  accessToken: ENV.STORYBLOK_ACCESS_TOKEN,
  bridge: isPreview(),
  use: [apiPlugin],
  components,
  richText: {
    schema: RichTextSchema
  }
});
// @ts-ignore
export const links: LinksFunction = () => {
  return [
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: sonnerStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  { rel: 'stylesheet', href: sonnerStyleSheetUrl },
  // {
  // 	rel: 'stylesheet',
  // 	href: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.57.0/adyen.css' as 'stylesheet',
  // },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'Plenty &' : 'Error | Plenty &' },
  {
    name: 'description',
    content: `Your own experience shopping in the city`
  },
  { name: 'p:domain_verify', content: 'aaa1c954f5bf1bf83f850c7f462dd240' }];

};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');

  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        firstName: true,
        commercetoolsId: true,
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }

  const langSession = await languageSession.getSession(
    request.headers.get('cookie')
  );
  let language: string = langSession.get('sessionLanguage');
  language = getLanguageFromCookieOrBrowserOrFallback(language, request);
  const locale = language;

  const { toast, headers: toastHeaders } = await getToast(request);
  const { visitorId, headers: visitorHeaders } =
  await getVisitorAndHeader(request);

  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const { cartId, cookieHeader: cartCookieHeader } = await ensureValidCartId(
    request,
    visitorId,
    user?.commercetoolsId ?? '' //TODO: change migration
  );

  const plentyCart = await getPlentyCart(request);

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      visitorId,
      honeyProps,
      csrfToken,
      plentyCart,
      cartId,
      locale,
      maintenance: process.env.MAINTENANCE_MODE == 'true'
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        visitorHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
        cartCookieHeader ? { 'set-cookie': cartCookieHeader } : null
      )
    }
  );
}

export let handle = {
  i18n: [
  'common',
  'navbar',
  'footer',
  'stores_page',
  'checkout',
  'returns',
  'product_details_page',
  'product_list_page',
  'search',
  'shopping_bag',
  'customer_portal',
  'categories',
  'store_page',
  'about_us',
  'return_and_refunds']

};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

function Document({
  children,
  nonce,
  theme = 'light',
  env = {},
  locale,
  i18nDir







}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;locale: string;i18nDir: string;}) {
  return (
    <html
      lang={locale}
      dir={i18nDir}
      className={`${theme} h-full overflow-x-hidden`}>

			<head>
				{/*<ClientHintCheck nonce={nonce} />*/}

				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{/* No idea if this gonna change the color of the status bar */}
				<meta name="theme-color" content="#FFFFFF" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black" />
				<meta
          name="google-site-verification"
          content="x4sjtRi6muk-mtRflzLfpaiuXDHCDdSgIMZl67RYoGA" />

				<meta
          name="trustpilot-one-time-domain-verification-id"
          content="adbf963d-aa87-4790-a317-5258e21b5378" />

				<Links />
			</head>

			<body
        className="bg-background text-foreground"
        // Fix to the select issue on the pdp -> check if there is another way to fix this
        ref={(node) => {
          if (node) {
            node.style.setProperty('overflow', 'visible', 'important');
          }
        }}>

				<noscript>
					<iframe
            title="google-tag-manager"
            src="https://www.googletagmanager.com/ns.html?id=GTM-TP69B8QR"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}>
          </iframe>
				</noscript>
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();

  let { i18n } = useTranslation();
  const { update: updateCart } = useCartStore();
  const { update: updateClerkVisitor } = useClerkVisitorStore();
  const location = useLocation();
  const metaPixelFetcher = useFetcher();
  const isDatadogInitialized = useRef(false);

  useEffect(() => {
    if (
    typeof window !== 'undefined' &&
    !isDatadogInitialized.current &&
    process.env.NODE_ENV === 'production')
    {
      // Determine if the current page is one of the targeted pages
      const targetPages = [
      '/checkout/payment',
      '/checkout',
      '/shopping-bag',
      '/order-confirmation',
      '/processing',
      '/returns/stepper/step1',
      '/returns/stepper/step2',
      '/returns/stepper/step3'];

      const isTargetPage = targetPages.some((page) =>
      window.location.pathname.includes(page)
      );

      datadogRum.init({
        applicationId: data.ENV.DATADOG_APPLICATION_ID || '',
        clientToken: data.ENV.DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: 'plentyand',
        env: 'production', // change to production if replays are needed in production
        sessionSampleRate: 100,
        sessionReplaySampleRate: isTargetPage ? 100 : 0, // 100% on target pages, 0% elsewhere
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow'
      });
      datadogRum.startSessionReplayRecording();
      isDatadogInitialized.current = true; //
    }
  }, [data.ENV.DATADOG_APPLICATION_ID, data.ENV.DATADOG_CLIENT_TOKEN]);

  useEffect(() => {
    if (data.plentyCart) {
      updateCart((data.plentyCart as PlentyCart));
    }
  }, [data.plentyCart, updateCart]);

  useEffect(() => {
    if (data.visitorId) updateClerkVisitor(data.visitorId);
  }, [data.visitorId, updateClerkVisitor]);

  UseCookieScript();
  UseMetaPixel();
  UseGoogleAdsScript();
  useGoogleTagManagerScript();
  useEffect(() => {
    const formData = new FormData();
    formData.append('eventType', 'PageView');

    metaPixelFetcher.submit(formData, {
      action: '/resources/trackPixelEvent',
      method: 'POST'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.fbq('track', 'ViewContent', {
        content_name: 'Page View',
        content_url: window.location.href
      });
    }
    const eventDataPixel = {
      event_name: 'ViewContent',
      content_name: 'Page View',
      content_url: window.location.href
    };

    const formData = new FormData();

    formData.append('eventType', 'ViewContent');
    formData.append('eventData', JSON.stringify(eventDataPixel));

    metaPixelFetcher.submit(formData, {
      action: '/resources/trackPixelEvent',
      method: 'POST'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (data.maintenance)
  return (
    <Document
      nonce={nonce}
      theme={theme}
      env={data.ENV}
      locale={data.locale}
      i18nDir={i18n.dir()}>

				<EpicToaster toast={data.toast} />
				<div className="flex min-h-screen flex-col">
					<PageIsDown />
				</div>
			</Document>);


  return (
    <Document
      nonce={nonce}
      theme={theme}
      env={data.ENV}
      locale={data.locale}
      i18nDir={i18n.dir()}>

			<EpicToaster toast={data.toast} />
			<div className="flex min-h-screen flex-col">
				<Outlet />
			</div>
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce} locale="" i18nDir="">
			<GeneralErrorBoundary />
		</Document>);

}